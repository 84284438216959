import React from 'react';

import './TitleBar.css';

export const TitleBar = () => {
  return (
    <nav className="title-bar">
      <h1>Hetherington Soundboard</h1>
    </nav>
  )
}